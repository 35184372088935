 
import Header from '../Component/Header'
import Footer from '../Component/Footer'
import Breadcrumb from '../Component/Breadcrumb'
const studentOutFit = () => {
    return (
        <>
            <Header />
            <Breadcrumb title="Students Out-Fit" pageName="Students Out-Fit" mid="Academics" />
            <div className="innerSection result-img">
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <h3 className='inner_title'>Students Out-Fit</h3>
                        </div>
                        <div className='col-md-12'>
                            <h3 className='sub_title'>Primary Section</h3>
                        </div>
                        <div className='col-lg-6'>
                            <div className='uniform'>
                                <p><span>Summer Uniform:</span></p>
                                <p><b>Boys:</b> Navy blue half pants, white shirt (half sleeves), school tie, school belt, black leather shoes and dark blue socks.</p>
                                <p><b>Girls:</b> Navy blue tunic, white shirt (half sleeves), school tie, school belt, black leather shoes and dark blue stockings.</p>
                            </div>
                        </div> 
                        <div className='col-lg-6'>
                            <div className='uniform'>
                                <p><span>Winter Uniform:</span></p>
                                <p><b>Boys:</b> Navy blue long pants, white shirt, tie, belt, black leather shoes and socks, navy blue half sweater with stripes on neck, sleeves and border and navy blue woollen blazer with school emblem.</p>
                                <p><b>Girls:</b> White shirt (full sleeves), navy blue tunic, school tie, school belt, black leather shoes and dark blue stockings, navy blue half sweater with stripes on neck, sleeves and border and navy blue blazer with school emblem.</p>
                            </div>
                        </div> 
                        <div className='col-lg-6'>
                            <div className='uniform'>
                                <p><span>P. T. Dress (Wednesday):</span></p>
                                <p><b>Boys:</b> White shirt, white half pants, school tie, belt, white canvas shoes with white socks.</p>
                                <p><b>Girls:</b> White skirt, white shirt, school tie, belt, white canvas shoes with stockings.</p>
                            </div>
                        </div> 
                        <div className='col-lg-6'>
                            <div className='uniform'>
                                <p><span>Games Dress:</span></p>
                                <p><b>Boys:</b> Colour ‘T’ shirt according to the House with school emblem, white half pants, white canvas shoes and white socks.</p>
                                <p><b>Girls:</b> Colour ‘T’ shirt according to the House with school emblem, white skirt, white canvas shoes and white stockings.</p>
                            </div>
                        </div> 
<div className='clr-20'></div>
                        <div className='col-md-12'>
                            <h3 className='sub_title'>Higher Section</h3>
                        </div>
                        <div className='col-lg-6'>
                            <div className='uniform'>
                                <p><span>Summer Uniform:</span></p>
                                <p><b>Boys:</b> Navy blue long pants, white shirt (half sleeves), school tie, school belt, black leather shoes and dark blue socks.</p>
                                <p><b>Girls:</b> Navy blue divided skirt, white shirt (half sleeves), school tie, school belt, black leather shoes and dark blue stockings.</p>
                                <p><b>Girls</b> (Class XI to XII): Salwaar Kameez</p>
                            </div>
                        </div> 
                        <div className='col-lg-6'>
                            <div className='uniform'>
                                <p><span>Winter Uniform:</span></p>
                                <p>(For boys and girls, besides their regular uniform)</p>
                                <p>Navy blue half sweater with two stripes on neck, sleeves and border, navy blue woollen blazer with school Emblem.</p>
                            </div>
                        </div> 
                        <div className='col-lg-6'>
                            <div className='uniform'>
                                <p><span>P. T. Dress (Wednesday):</span></p>
                                <p><b>Boys:</b> White shirt (full sleeves), white long pants, school tie, belt, white canvas tennis shoes and white stockings.</p>
                                <p><b>Girls:</b> White shirt (half sleeves), white divider skirt, school tie, belt, white canvas tennis shoes with white stockings.</p>
                            </div>
                        </div> 
                        <div className='col-lg-6'>
                            <div className='uniform'>
                                <p><span>Games Dress:</span></p>
                                <p><b>Boys:</b> ‘T’ shirt as per the House colour with school emblem, white full pants, white canvas shoes and white socks.</p>
                                <p><b>Girls:</b> ‘T’ shirt as per the House colour with school emblem, white divided skirt, white canvas shoes and white stockings.</p>
                            </div>
                        </div> 
                        <div className='col-md-12'>
                            <p>The uniform must be neat and clean. Students who fail to be in uniform as required and those who come in shabby and untidy dress will be denied entry into the class and are liable to be sent home.</p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default studentOutFit