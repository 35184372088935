import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom"; // Import useNavigate hook
import { getSubGallery } from "../Service/Api";
import { Link } from 'react-router-dom'
import Header from '../Component/Header'
import Footer from '../Component/Footer'
import Breadcrumb from '../Component/Breadcrumb'
import LazyLoad from "react-lazyload";
const SubGallery = () => { 
  const location = useLocation();
  const id = new URLSearchParams(location.search).get("id");
  const [data, setData] = useState("");
  const [index, setIndex] = React.useState(-1);

  console.log(id);
 

  useEffect(() => {
    const getData = async () => {
      try {
        let subgalleryData = await getSubGallery(id);
        console.log(subgalleryData);
        setData(subgalleryData);
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, [id]); // Add id as a dependency


  console.log(data);

  const slides = data?.attachments?.map(img => ({
    src: `https://webapi.entab.info/api/image/${img}`
  }));
  return (
    <>
      <Header />
      <Breadcrumb title="Sub Gallery" pageName="Sub Gallery" mid="Gallery" />
      <div className="innerSection">
        <div className="container">
          <div className="row">
            <div className="col-md-12">



              <div className="fulwidth">
                <div className="row tabs-dynamic">
                  <div className="col-xxl-3 col-xl-3 col-lg-6 col-12"><Link to="/gallery"><i className="bi bi-arrow-left"></i></Link></div>
                  <div className="col-xxl-3 col-xl-3 col-lg-6 col-12"><b>Event Date:</b> {" "}{new Date(data.date).toLocaleDateString("en-GB")}</div>
                  <div className="col-xxl-3 col-xl-3 col-lg-6 col-12"><b>Updated On:</b>  {new Date(data.date).toLocaleDateString("en-GB")}</div>
                  <div className="col-xxl-3 col-xl-3 col-lg-6 col-12"><b>Total Photos:</b> {data?.attachments?.length}</div>
                </div>


                <div className="discription">
                  <h3 className="sub_title">{data.title}</h3>
                  <p><b>Description:</b>{data.description}</p>
                </div>
              </div>
              <div className="flexbox-v zoompic row">
              {data && data.attachments && data.attachments.length > 0 && (
  data.attachments.map((img, index) => (
    <div className="col-md-4" key={index}>
      <a data-magnify="gallery" data-src="" data-group="a" href={`https://webapi.entab.info/api/image/${img}`} onClick={() => setIndex(index)}>
        <LazyLoad><img src={`https://webapi.entab.info/api/image/${img}`} className="img-fluid border_img" alt={`Image ${index}`} /></LazyLoad>
      </a>
    </div>
  ))
)}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default SubGallery