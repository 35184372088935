
import Header from '../Component/Header'
import Footer from '../Component/Footer'
import Breadcrumb from '../Component/Breadcrumb'
import LazyLoad from 'react-lazyload'
const Registration = () => {
    return(
        <>
        <Header />
        <Breadcrumb title="Student Registration" pageName="Student Registration" mid="Prospectus" />
        <div className="innerSection">
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <h3 className='inner_title'>Student Registration</h3>
                    </div>
                    <div className='col-lg-7'>
                        <ul>
                            <li>The academic year of the school is from April to March. Admission to LKG and Class VI are taken in December.</li>
                            <li>Applications to other classes will be entertained only at the instance of vacancies in that class, which shall be announced when the session begins in July.</li>
                            <li>The prospectus with Registration Form shall be available from the School Office during the prescribed hours.</li>
                            <li>Registration Fees is Rs. 500/- which is non refundable.</li>
                        </ul>
                        <p><b>Registration does not guarantee admission; it will be subject to merit and availability of seats.</b></p>
                    </div>
                    <div className='col-lg-5'>
                        <LazyLoad><img src="https://webapi.entab.info/api/image/LFSGV/public/Images/register.jpg" className='img-fluid border_img mt-0' alt="Little Flower School" /></LazyLoad>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
        </>
    )
}
export default Registration