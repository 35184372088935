
import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Home from'./Pages/Home' 
import About from'./Pages/about' 
import Aims from'./Pages/aims' 
import Motto from'./Pages/motto' 
import Infrastructure from'./Pages/infrastructure' 
import Result from'./Pages/result' 
import OutFit from'./Pages/outFit' 
import FeeStructure from'./Pages/feeStructure' 
import CoCurricular from'./Pages/coCurricular' 
import ExaminationAndPromotion from'./Pages/examinationAndPromotion' 
import Rules from'./Pages/rules' 
import Attendance from'./Pages/attendance' 
import Calender from'./Pages/calender' 
import Registration from'./Pages/registration' 
import Requirement from'./Pages/requirement' 
import Admission from'./Pages/admission' 
import Withdrawal from'./Pages/withdrawal' 
import Recommendation from'./Pages/recommendation' 
import Instructions from'./Pages/instruction' 
import TimeTable from'./Pages/timetable' 
import Tc from'./Pages/Tc' 
import Contact from'./Pages/contact' 
import Career from'./Pages/career' 
import Gallery from'./Pages/gallery' 
import News from'./Pages/news' 
import SubGallery from'./Pages/subGallery' 
import Events from'./Pages/events' 
import ERPSoftware from'./Pages/ERPSoftware' 
import ParentPortal from'./Pages/ParentPortal' 
import TopperX from'./Pages/topperX' 
import TopperXII from'./Pages/topperXII' 
function App() { 
  return (
      <>
      <BrowserRouter>
      <Routes>
      <Route path="/" element={<Home/>} />
      <Route path="/about" element={<About/>} />
      <Route path="/aims" element={<Aims/>} />
      <Route path="/motto" element={<Motto/>} />
      <Route path="/infrastructure" element={<Infrastructure/>} />
      <Route path="/result" element={<Result/>} />
      <Route path="/outFit" element={<OutFit/>} />
      <Route path="/feeStructure" element={<FeeStructure/>} />
      <Route path="/coCurricular" element={<CoCurricular/>} />
      <Route path="/examinationAndPromotion" element={<ExaminationAndPromotion/>} />
      <Route path="/rules" element={<Rules/>} />
      <Route path="/attendance" element={<Attendance/>} />
      <Route path="/calender" element={<Calender/>} />
      <Route path="/registration" element={<Registration/>} />
      <Route path="/requirement" element={<Requirement/>} />
      <Route path="/admission" element={<Admission/>} />
      <Route path="/withdrawal" element={<Withdrawal/>} />
      <Route path="/recommendation" element={<Recommendation/>} />
      <Route path="/instruction" element={<Instructions/>} />
      <Route path="/timetable" element={<TimeTable/>} />
      <Route path="/Tc" element={<Tc/>} />
      <Route path="/contact" element={<Contact/>} />
      <Route path="/career" element={<Career/>} />
      <Route path="/gallery" element={<Gallery/>} />
      <Route path="/news" element={<News/>} />
      <Route path="/subGallery" element={<SubGallery/>} />
      <Route path="/events" element={<Events/>} />
      <Route path="/ERPSoftware" element={<ERPSoftware/>} />
      <Route path="/ParentPortal" element={<ParentPortal/>} />
      <Route path="/topperX" element={<TopperX/>} />
      <Route path="/topperXII" element={<TopperXII/>} />
      </Routes>
     </BrowserRouter>
      </>
  )
}

export default App;
