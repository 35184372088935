
import Header from '../Component/Header'
import Footer from '../Component/Footer'
import Breadcrumb from '../Component/Breadcrumb'
import LazyLoad from 'react-lazyload'
const Infrastructure = () => {
    return (
        <>
            <Header />
            <Breadcrumb title="Infrastructure" pageName="Infrastructure" mid="About" />
            <div className="innerSection">
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>                            
                            <h3 className='inner_title'>Infrastructure</h3>
                        </div>                        
                    </div>
                    <div className='row infra_blk'>
                        <div className='col-lg-4'>
                            <LazyLoad><img src="https://webapi.entab.info/api/image/LFSGV/public/Images/infra1.jpg" className='img-fluid border_img' alt="Little Flower School" /></LazyLoad>
                        </div>
                        <div className='col-lg-8'>
                            <h3 className='sub_title'>Audio Visual Room</h3>
                            <p>The School Campus provides a huge infrastructure, a vibrant atmosphere, well maintained play grounds, spacious, well lit and well ventilated class rooms three activity halls.</p>
                        </div>
                    </div>
                    <div className='row infra_blk'>                      
                        <div className='col-lg-8'>
                            <h3 className='sub_title'>Science Laboratories</h3>
                            <p>The School has well equipped science lab of Physics, Chemistry, Biology and Biotechnology with the latest apparatus to accommodate more than hundred students at a time. The basic purpose of the lab is to prepare children for life by instilling in them an approach to analyze problems and find solutions on their own.</p>
                        </div>
                        <div className='col-lg-4'>
                        <LazyLoad>    <img src="https://webapi.entab.info/api/image/LFSGV/public/Images/infra2.jpg" className='img-fluid border_img' alt="Little Flower School" /></LazyLoad>
                        </div>
                    </div>
                    <div className='row infra_blk'>
                         <div className='col-lg-4'>
                           <LazyLoad> <img src="https://webapi.entab.info/api/image/LFSGV/public/Images/infra1.jpg" className='img-fluid border_img' alt="Little Flower School" /></LazyLoad>
                        </div>
                        <div className='col-lg-8'>
                            <h3 className='sub_title'>Bio-tech Laboratories</h3>
                            <p>In order to create an awareness to appreciate biological phenomenon in nature and contribution of Biotechnology to human welfare Biotechnology was introduced in the school (2003) in the same year the council introduced the subject at the ISC level. Little Flower is the only school in Gorakhpur offering Biotechnology at the ISC level. School has a well set up lab with experienced and qualified staff to guide students in developing scientific attitude towards the biological phenomenon and to appreciate the role played by Biotechnology in improving healthcare for human beings.</p>
                        </div>
                       
                    </div>
                    <div className='row infra_blk'>
                        <div className='col-lg-8'>
                            <h3 className='sub_title'>Computer Laboratories</h3>
                            <p>Computer Science was introduced into the school curriculum in 1988 with the intention of accelerating a creative interest in modern technology and scientific knowledge.</p>
                            <p>It is fully equipped with the latest state of the art computer software, hardware and networking. It is actively used under the guidance of well qualified and experienced staff for imparting computer skills and knowledge to the students.</p>
                        </div>
                        <div className='col-lg-4'>
                          <LazyLoad> <img src="https://webapi.entab.info/api/image/LFSGV/public/Images/infra4.jpg" className='img-fluid border_img' alt="Little Flower School" /></LazyLoad>
                        </div>
                    </div> 
                    <div className='row infra_blk'>
                          <div className='col-lg-4'>
                          <LazyLoad>  <img src="https://webapi.entab.info/api/image/LFSGV/public/Images/infra1.jpg" className='img-fluid border_img' alt="Little Flower School" /></LazyLoad>
                        </div>
                        <div className='col-lg-8'>
                            <h3 className='sub_title'>Smart Class</h3>
                            <p>A good number of our class rooms are converted into technology enabled Smart Class Rooms equipped with interactive Digi Board Systems. A powerful Smart Class Application Engine allows teachers to view and plan relevant digital content for their class room sessions as per the syllabus. Thus, the students are given maximum chances to develop into a challenging human in this world.</p>
                        </div>
                      
                    </div> 
                    <div className='row infra_blk'>
                        <div className='col-lg-8'>
                            <h3 className='sub_title'>Library</h3>
                            <p>The School has a well-stocked, spacious library to quench the children’s intellectual craving. The library helps the children to upgrade and update their knowledge and reinforce their class room learning. The treasure trove of our library are illustrated encyclopedias, books, story books selected daily newspapers, weekly and monthly magazines and periodicals.</p>
                        </div>
                        <div className='col-lg-4'>
                            <LazyLoad><img src="https://webapi.entab.info/api/image/LFSGV/public/Images/infra1.jpg" className='img-fluid border_img' alt="Little Flower School"/></LazyLoad>
                        </div>
                    </div> 
                    <div className='row infra_blk'> 
                    <div className='col-lg-4'>
                            <LazyLoad><img src="https://webapi.entab.info/api/image/LFSGV/public/Images/infra1.jpg" className='img-fluid border_img' alt="Little Flower School"/></LazyLoad>
                        </div>
                        <div className='col-lg-8'>
                            <h3 className='sub_title'>School Bus Facility</h3>
                            <p>School buses ply for the transportation of the children. However ;</p>
                            <ul>
                                <li>Preference will be given to the children of the LOWER CLASSES.</li>
                                <li>Conveyance fees will be charged for all twelve months; it is non refundable.</li>
                                <li>Bus fees is taken in one installment only.</li>
                                <li>Bus will run on prescribed routes and fixed days only. Conveyance fee is to be remitted in the school office</li>
                                <li>Conveyance facility shall not be granted to less than a session (12 Months).</li>
                            </  ul>
                        </div>
                       
                    </div>
                    <div className='row infra_blk'>
                        <div className='col-lg-8'>
                            <h3 className='sub_title'>Infirmary</h3>
                            <p>A well-equipped medical room separately for boys and girls  takes care of all minor ailments and also deals with emergencies during school hours.  Doctors are available on call to meet any emergency. Regular medical check-up is carried out for the benefit of the children. Special attention is given to height, weight and vision.</p>
                            <ul>
                                <li><b>'Little Flower Gold Medal'</b>  for the topper in ICSE Examinations (donated by Fr. K.J. Mathew, Founder & First Principal of the School)</li>
                                <li><b>'Little Flower Gold Medal'</b> for the topper in ISC-XII Examinations. </li>
                                <li><b>'Shantanu Sinha Gold Medal'</b>  for the topper in Class XI (donated by Dr. V.K. Sinha in memory of his late son Shantanu Sinha)</li>
                                <li><b>'Yogeshwar Anand Murti Gold Medal'</b> for the topper in Biology in ISC-XII Examination (donated by Dr. S.C. Tripathi) </li>
                               <li><b>'Smt Vimala Srivastava Gold Medal'</b> for the topper in Mathematics in ISC-XII (donated by Smt. Prabha Srivastava)</li>
                               <li><b>'Smt. Archana Tiwari Gold Medal'</b> for the topper in Chemistry in ISC- XII Examination (donated by Dr. Archana Tiwari, W/o Shri R.K. Tiwari, Commissioner of Gorakhpur 1992 – 1993)</li>
                               <li><b>'Jagpreet Singh Anand Gold Medal'</b> for the topper in Computer Science ISC- XII (instituted in memory of late Jagpreet Singh Anand, Student of Little Flower School 1990 – 2002)</li>
                               <li><b>'Pallavi Memorial Gold Medal'</b> for the topper in Bio-Technology, ISC- XII (instituted in memory of late Pallavi, Student of Little Flower School 1991 – 2006)</li>
                               <li><b>'Pratibha Srivastava Gold Medal'</b> for the topper in Mathematics ISC- XII (donated by Pratibha Srivastava)</li>
                            </  ul>
                        </div>
                        <div className='col-lg-4'>
                            <LazyLoad><img src="https://webapi.entab.info/api/image/LFSGV/public/Images/infra1.jpg" className='img-fluid border_img' alt="Little Flower School" /></LazyLoad>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default Infrastructure