import React from 'react'
import LazyLoad from 'react-lazyload'
import { Link } from 'react-router-dom'
const Campuscare = () => {
  return (
     <>
      <div className="campuslogo">
         <Link to="https://www.lfscampuscare.org/" target="_blank">
         <LazyLoad><img src="https://webapi.entab.info/api/image/LFSGV/public/Images/Campulogo.png" className="img-fluid float-end campus" alt="Little Flower School, Gita Vatika"/> </LazyLoad>
         </Link>
      </div>
      </>
        
  )
}

export default Campuscare
