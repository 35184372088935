import { useEffect, useState } from "react";
import { getTopper } from "../Service/Api";
import Slider from "react-slick";
import LazyLoad from "react-lazyload";
const TopperXII = () => {
  const [classXIIData, setClassXIIData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const topperData = await getTopper();
      const currentYear = new Date().getFullYear();
      const yearString = `${currentYear - 1}-${currentYear}`;

      const classXII = topperData.filter((item) => item.class === 'XII' && item.sessionYear === yearString); // Adjust the filtering condition as needed
      setClassXIIData(classXII);

      console.log("Filtered class XII data for year", yearString, classXII);
    };
    fetchData();
  }, []);




  var settings = {
    autoplay: true,
    infinite: true,
    autoplaySpeed: 2000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };
  return (
    <>
      <div className="row">
        <Slider {...settings}>

          {classXIIData.length > 0 ? (classXIIData.map((item1, index) => (
            <div className="item" key={index}>
              <div className="topperdiv">
                <div className="toppertaj">
                  <LazyLoad><img src={`https://webapi.entab.info/api/image/${item1.attachments}`} className="img-fluid" /></LazyLoad>
                </div>

                <div className="topperdesc">
                  <p className="name">{item1?.name}<span>{item1?.stream}</span></p>
                  <p className="per">{item1?.division}</p>
                </div>
              </div>
            </div>))) : (
            <div className="item">
              <div className="topperdiv">
                <div className="toppertaj">
                  <LazyLoad><img src="https://webapi.entab.info/api/image/LFSGV/public/Images/topper.jpg" className="img-fluid" /></LazyLoad>
                </div>
                <div className="topperdesc">
                  <p className="name">Student Name<span>Stream</span></p>
                  <p className="per">Marks</p>
                </div>
              </div>
            </div>
          )}
          {/* <div className="item">
            <div className="topperdiv">
              <div className="toppertaj">
                <img src="https://webapi.entab.info/api/image/LFSGV/public/Images/topper.jpg" className="img-fluid" />
              </div>
              <div className="topperdesc">
                <p className="name">Sushant prakash<span>science</span></p>
                <p className="per">96.4%</p>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="topperdiv">
              <div className="toppertaj">
                <img src="https://webapi.entab.info/api/image/LFSGV/public/Images/topper.jpg" className="img-fluid" />
              </div>
              <div className="topperdesc">
                <p className="name">Sushant prakash<span>science</span></p>
                <p className="per">96.4%</p>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="topperdiv">
              <div className="toppertaj">
                <img src="https://webapi.entab.info/api/image/LFSGV/public/Images/topper.jpg" className="img-fluid" />
              </div>
              <div className="topperdesc">
                <p className="name">Sushant prakash<span>science</span></p>
                <p className="per">96.4%</p>
              </div>
            </div>
          </div> */}

        </Slider>
      </div>
    </>
  )
}

export default TopperXII