import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getNews } from '../Service/Api';
const News = () => {
  const [data, setData] = useState([]); // State for gallery data
  const [loading, setLoading] = useState(true); // State for loading indicator 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const topperData = await getNews();
        setData(topperData);
      } catch (error) {
        console.error("Error fetching gallery data:", error);
        // Handle error (e.g., show error message)
      } finally {
        setLoading(false); // Set loading to false after data fetching is complete
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <div className="newsbg">
        <marquee onMouseOver={(event) => event.currentTarget.stop()} direction="up" onMouseOut={(event) => event.currentTarget.start()}>
          <ul className="newsboxlist">
            {data.length > 0 ? (data.map((item, index) => (
              <li key={index}>
                <div className="newsbox">
                  <div className="newsdesc">
                    <h6>{item.title}</h6>
                    <p>{item.description}</p>
                  </div>
                  {item.attachments && item.attachments.length > 0 && (<Link to={`https://webapi.entab.info/api/image/${item.attachments}`} target="_blank"><i className="bi bi-paperclip"></i></Link>)}
                </div>
              </li>
            ))
            ) : (
              <li>
                <div className="newsbox">
                  <div className="newsdesc">
                    <h6>News Title</h6>
                    <p>Stay Tunned For More Updates</p>
                  </div>
                  <Link to="#" target="_blank"><i className="bi bi-paperclip"></i></Link>
                </div>
              </li>
            )}
            {/* <li>
                   <div className="newsbox">
                       <div className="newsdesc">
                           <h6>School Timing Class I to X and Summer Vacation Nur</h6>
                           <p>Timing Class I to X and Summer Vacation Nursery, LKG, UKG</p>
                        </div>
                        <Link to="#" target="_blank"><i className="bi bi-paperclip"></i></Link>
                   </div>
                   </li>
                   <li>
                   <div className="newsbox">
                       <div className="newsdesc">
                           <h6>New Library books</h6>
                           <p>Dear students, "A reader lives a thousand lives before he dies" by George R R Martin. here is th...</p>
                        </div>
                        <Link to="#" target="_blank"><i className="bi bi-paperclip"></i></Link>
                   </div>
                   </li>  */}
          </ul>
        </marquee>
        <button className='button light'> <Link to="/news">View More</Link></button>
      </div>
    </>
  )
}

export default News
