
import Header from '../Component/Header'
import Footer from '../Component/Footer'
import Breadcrumb from '../Component/Breadcrumb'
const Contact = () => {
    return (
        <>
            <Header />
            <Breadcrumb title="Contact Us" pageName="Contact Us" mid="Contact" />
            <div className="innerSection contact">
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <h3 className='inner_title'>Contact Us</h3>
                        </div>
                        <div className='col-lg-6'> 
                            <h3 class="sub_title">Little Flower School</h3>
                            <p><i class="bi bi-geo-alt-fill"></i> St. Anthony Nagar, PO - Gita Vatika,  Dharampur, Gorakhpur - 273006 </p>
                            <p><i class="bi bi-telephone-fill"></i>  0551-2283454</p>
                            <p><i class="bi bi-envelope-fill"></i> <a href="mailto:lfsgorakhpur@gmail.com" className="emaillink"> lfsgorakhpur@gmail.com</a></p> 
                        </div>
                        <div className='col-lg-6'>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4235.937997366417!2d83.38685899236647!3d26.77674561120024!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399144fc81fa5f41%3A0x1d8995c0c8a589e5!2sLittle+Flower+School%2C+Gorakhpur!5e0!3m2!1sen!2sin!4v1548392680211" width="100%" height="400px" frameborder="0" style={{border:0}} allowfullscreen=""></iframe>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default Contact