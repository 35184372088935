
import Header from '../Component/Header'
import Footer from '../Component/Footer'
import Breadcrumb from '../Component/Breadcrumb'
const TimeTable = () => {
    return (
        <>
            <Header />
            <Breadcrumb title="Time Table of ICSE & ISC" pageName="Time Table of ICSE & ISC" mid="Download" />
            <div className="innerSection">
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <h3 className='inner_title'>Time Table of ICSE & ISC</h3> 
                        </div>
                        <div className='col-lg-12'>
                            <iframe src="https://webapi.entab.info/api/image/LFSGV/public/pdf/ICSE-ISC-2019-TimeTables-Combined.pdf" height="800" width="100%"></iframe>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default TimeTable