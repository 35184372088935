
import Header from '../Component/Header'
import Footer from '../Component/Footer'
import Breadcrumb from '../Component/Breadcrumb'
const Requirement = () => {
    return(
        <>
        <Header />
        <Breadcrumb title="Requirement for Registration" pageName="Requirement for Registration School" mid="Prospectus" />
        <div className="innerSection">
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <h3 className='inner_title'>Requirement for Registration School</h3>
                    </div>
                    <div className='col-lg-12'>
                        <h3 className='sub_title'>Registration Eligibility for Class VI</h3>
                        <ul>
                            <li>Birth Certificate from the school where the student is studying.</li>
                            <li><b>Age:</b> A student seeking admission in class VI shall have completed 10 and half years on March 31st.</li>
                            <li>Date of birth shall not be changed at any stage later.</li>
                            <li>Stamp-size photographs of the student to be affixed on the Application Form and Interview Slip.</li>
                            <li>Written Test will be taken in English, Hindi, Mathematics, Social Studies and General Science.</li>
                            <li>Candidates short listed for admission on the basis of written test may be called for an interview along with their parents.</li>
                            <li>Minimum marks required will be 60% in every subject separately and in interview.</li>
                            <li>Report Card of Class V Half Yearly Examination shall be submitted at the time of written test.</li>
                        </ul>
                        <h3 className='sub_title'>Registration Eligibility for Class LKG</h3>
                        <ul>
                            <li>A child who has completed three and half years on March 31st of the year of Admission</li>
                            <li>Date of birth Certificate:<br></br>
                            Certificate from the Parish (in the case of Christian Children).<br></br>Birth Certificate from the Competent authority, such as: Panchayat/ Mahanagarpalika/ Nagarpalika<br></br>Other certificate from whatever source it be, will be accepted.<br></br>Date of Birth once given shall not be changed on any account whatsoever.</li>
                            <li>The child to be registered must be accompanied by the parents.</li>
                            <li>Recent stamp-size photographs to be affixed on the Application Form and Acknowledgement Slip.</li>
                            <li>An interaction with the child registered for admission shall be taken on the date informed.<br></br>
                                <p>Children brought for interaction shall be able to: recognize and recite English Alphabets, recognize and recite Hindi Alphabets, recognize and count numbers 1 – 20, recognize colours, flowers, animals, birds and fruits, answer questions smartly and intelligently.</p>
                                <p>The INTEREST and AVAILABILITY of the parents in helping the child will be specially counted in the selection of children for admission.</p>
                            </li>
                        </ul> 
                        <p><b>*Note: ADMISSION WILL BE STRICTLY ON THE BASIS OF THE WRITTEN TEST AND THE INTERVIEW.</b></p>
                    </div>
                    
                </div>
            </div>
        </div>
        <Footer />
        </>
    )
}
export default Requirement