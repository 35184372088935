import React, { useEffect, useState } from "react";
import { getEvents } from "../Service/Api";
import { Link } from 'react-router-dom'
import Slider from "react-slick";
import LazyLoad from "react-lazyload";
const HomeEvent = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
     const getData = async () => {
        const datas = await getEvents();
        setData(datas);
     };
     getData();
  }, []);
    var settings = { 
        autoplay: true,
        infinite: true,
        autoplaySpeed: 2000,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,  
        arrows:false,
        adaptiveHeight: true,
        responsive: [
            {
              breakpoint: 1300,
              settings: {
                slidesToShow: 3, 
              }
            },
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 2, 
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow:2,  
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1 
              }
            }
          ]
      };
  return (
     <> 
     <div className="row">
     <div className="eventbg">
     <Slider {...settings}> 
     {data.length > 0 && data.map((item, index) => (
      <div className="item">
                         <div className="homeeventbox">
                              <div className="homeeventboximg">
                                <LazyLoad> <img src={`https://webapi.entab.info/api/image/${item.images}`} className="img-fluid" alt="little flower School"/> </LazyLoad>
                                  
                              </div>
                              <div className="homeeventboxdesc">
                              <div className="homeeventboxbottom">
                                     <span>{item.date}</span> 
                                 </div>
                                 <div className="homeeventboxtitle"><p>{item.title} </p>
                                 <p>  <span>   <LazyLoad><img src="https://webapi.entab.info/api/image/LFSGV/public/Images/location.png" className="img-fluid" alt="little flower School"/></LazyLoad> {item.venue}</span></p>
                                 </div>
                               
                              </div>
                          </div>
                    </div>
                     ))}
      {/* <div className="item">
                         <div className="homeeventbox">
                              <div className="homeeventboximg">
                                 <img src="https://webapi.entab.info/api/image/LFSGV/public/Images/event2.png" className="img-fluid" alt="little flower School"/>
                                  
                              </div>
                              <div className="homeeventboxdesc">
                              <div className="homeeventboxbottom">
                                     <span>17-07-2024</span> 
                                 </div>
                                 <div className="homeeventboxtitle"><p>Muharram</p>
                                 <p>  <span>   <img src="https://webapi.entab.info/api/image/LFSGV/public/Images/location.png" className="img-fluid" alt="little flower School"/>School Campus</span></p>
                                 </div>
                               
                              </div>
                          </div>
                    </div> 
     
      <div className="item">
                         <div className="homeeventbox">
                              <div className="homeeventboximg">
                                 <img src="https://webapi.entab.info/api/image/LFSGV/public/Images/event3.png" className="img-fluid" alt="little flower School"/>
                                  
                              </div>
                              <div className="homeeventboxdesc">
                              <div className="homeeventboxbottom">
                                     <span>15-08-2024</span> 
                                 </div>
                                 <div className="homeeventboxtitle"><p>Independence Day</p>
                                 <p>  <span>   <img src="https://webapi.entab.info/api/image/LFSGV/public/Images/location.png" className="img-fluid" alt="little flower School"/> School Campus</span></p>
                                 </div>
                               
                              </div>
                          </div>
                    </div>  */}
     
       </Slider>
       <button className="button">
        <Link to="/events">
        Read More</Link>
       </button>
     </div>
     </div>
     </>
  )
}

export default HomeEvent