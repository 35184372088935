
import Header from '../Component/Header'
import Footer from '../Component/Footer'
import Breadcrumb from '../Component/Breadcrumb'
const About = () => {
    return (
        <>
            <Header />
            <Breadcrumb title="Attendance & Leave" pageName="Attendance & Leave" mid="Academics" />
            <div className="innerSection">
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <h3 className='inner_title'>Attendance & Leave</h3>
                            <ul>
                                <li> Admission of the student in the school will be on the basis of merit.</li>
                                <li>For admission the necessary documents such as Transfer Certificate from the institution last attended and the copy of the Mark List of the qualifying examination must be submitted.</li>
                                <li>For taking admission the parents of the student must inevitably be present and sign the admission form.</li>
                                <li>If a student is absent from the school for three consecutive days from the day of re-opening, without forwarding a leave application in writing, his/her name will be struck off the rolls. If a student is absent from the school for Ten days consecutively without leave application, his/her name will be removed from the school rolls. In both cases the re-admission will be considered as fresh admission.</li>
                                <li>Applications for Transfer Certificate must be forwarded by the parent/guardian of the student. Transfer Certificate will be issued only after the student has paid all the dues and fears towards the school.</li>
                                <li>If a student is leaving the school during the school year he/she will have to pay all the dues and fees for the whole of the year, for Transfer Certificate to be issued in his/her favour.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default About