import { Link } from 'react-router-dom'
const Breadcrumb = ({ title, mid, pageName }) => {
    return(
        <>
            <div className="breadcrumb">
                <h2>{title}</h2>
                <ul>
                    <li><Link to="/">Home</Link></li>
                    <li> {mid} </li>
                    <li>{pageName}</li>
                </ul>
            </div>
        </>
    )
}
export default Breadcrumb