
import Header from '../Component/Header'
import Footer from '../Component/Footer'
import Breadcrumb from '../Component/Breadcrumb'
const Instructions = () => {
    return(
        <>
        <Header />
        <Breadcrumb title="Instruction to Students" pageName="Instruction to Students" mid="Prospectus" />
        <div className="innerSection">
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12'>
                         <h3 className='inner_title'>Instruction to Students</h3>
                        <ul>
                            <li>In your dress and behaviour keep a sense of self-respect. Right behaviour is the expression of fine character and a good personality.</li>
                            <li>Be simple in your ways and words.</li>
                            <li>Avoid using indecent language.</li>
                            <li>Courtesy begets courtesy. Make use of words ‘please’ and ‘thanks.’ If you have to criticise, do not be destructive, but be constructive.</li>
                            <li>In all your dealings with others be polite and obliging. Always try to be a good companion to help others and hurt nobody.</li>
                            <li>Respect your elders and teachers, whenever you meet them, greet them with respect and love.</li>
                            <li>Be polite and helpful to the strangers coming to the school.</li>
                            <li>Before and after every class stand up for prayer.</li>
                            <li>No exemption shall be given from the school programmes and functions. Every student is supposed to take part and co-operate in the various school activities such as cultural programmes; sports and games, drill etc.</li>
                            <li>Students are exhorted to make use of the Library and Reading Room. Books and periodicals must be carefully handled and kept neat and tidy.</li>
                            <li>English is the medium of communication in the school premises. Speak only in English during the School hours. Respect the beauty of the school premises and report any damage which you may observe.</li>
                            <li>Avoid dropping papers on the school premises and pickup any such papers dropped by others.</li>
                        </ul>
                    </div>
                    
                </div>
            </div>
        </div>
        <Footer />
        </>
    )
}
export default Instructions