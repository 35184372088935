import React from 'react'
const HomeSlider = () => {
  return (
   <>
   <div className="slider-sec"> 
   <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel"> 
  
   <div className="carousel-inner"> 
   {/* <div className="carousel-item " data-bs-interval="3000"> <img src="https://webapi.entab.info/api/image/LFSGV/public/Images/sld-1.png" className="d-block w-100" alt="Little Flower School, Gita Vatika"/> </div>  */}
   <div className="carousel-item active" data-bs-interval="3000"> <img src="https://webapi.entab.info/api/image/LFSGV/public/Images/sld-2.jpg" className="d-block w-100" alt="Little Flower School, Gita Vatika"/> </div> 
   <div className="carousel-item" data-bs-interval="3000"> <img src="https://webapi.entab.info/api/image/LFSGV/public/Images/sld-3.jpg" className="d-block w-100" alt="Little Flower School, Gita Vatika"/> </div> 
   <div className="carousel-item" data-bs-interval="3000"> <img src="https://webapi.entab.info/api/image/LFSGV/public/Images/sld-4.jpg" className="d-block w-100" alt="Little Flower School, Gita Vatika"/> </div> 
   <div className="carousel-item" data-bs-interval="3000"> <img src="https://webapi.entab.info/api/image/LFSGV/public/Images/sld-5.jpg" className="d-block w-100" alt="Little Flower School, Gita Vatika"/> </div> 
   <div className="carousel-item" data-bs-interval="3000"> <img src="https://webapi.entab.info/api/image/LFSGV/public/Images/sld-6.jpg" className="d-block w-100" alt="Little Flower School, Gita Vatika"/> </div> 
   <div className="carousel-item" data-bs-interval="3000"> <img src="https://webapi.entab.info/api/image/LFSGV/public/Images/sld-7.jpg" className="d-block w-100" alt="Little Flower School, Gita Vatika"/> </div> 
   <div className="carousel-item" data-bs-interval="3000"> <img src="https://webapi.entab.info/api/image/LFSGV/public/Images/sld-8.jpg" className="d-block w-100" alt="Little Flower School, Gita Vatika"/> </div> 
   <div className="carousel-item" data-bs-interval="3000"> <img src="https://webapi.entab.info/api/image/LFSGV/public/Images/sld-9.jpg" className="d-block w-100" alt="Little Flower School, Gita Vatika"/> </div> 
   
   </div> <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev"> <span className="carousel-control-prev-icon" aria-hidden="true"></span> <span className="visually-hidden">Previous</span> </button> <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next"> <span className="carousel-control-next-icon" aria-hidden="true"></span> <span className="visually-hidden">Next</span> </button> 
   </div> 
       
   <div className="slider-info">
   <div id="carouselExampleCaptions1" className="carousel slide" data-bs-ride="carousel1"> 
   <div className="carousel-indicators">
      <button type="button" data-bs-target="#carouselExampleCaptions1" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button> 
      <button type="button" data-bs-target="#carouselExampleCaptions1" data-bs-slide-to="1" aria-label="Slide 2"></button>  
   </div> 
 
   </div> 
   </div>
   </div>
   
   </>
  )
}

export default HomeSlider
