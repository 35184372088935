
import Header from '../Component/Header'
import Footer from '../Component/Footer'
import Breadcrumb from '../Component/Breadcrumb'
const Recommendation = () => {
    return(
        <>
        <Header />
        <Breadcrumb title="Recommendation to Parents" pageName="Recommendation to Parents" mid="Prospectus" />
        <div className="innerSection">
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12'>
                         <h3 className='inner_title'>Recommendation to Parents</h3>
                        <ul>
                            <li>Parents are expected to co-operate with the school authorities by ensuring that the children observe regularity, punctuality and discipline, prepare their lessons and take an active part in the activities of the school.</li>
                            <li>Private tuition is not encouraged. Instead, the parents are requested to give personal attention to the children and help them in their doubts as far as possible.</li>
                            <li>Parents should check the school diary & calendar regularly and sign any report recorded by the teachers, and the assignments and home work given.</li>
                            <li>Parents/guardians are requested to be in constant touch with the school to have a clear knowledge of their wards progress in studies. They must by all means attend the parent-teacher meeting to consult with the teachers regarding their children.</li>
                            <li>Parents are not allowed to interview the teachers in the class-room or in the staff-room, without the permission of the Principal. Those who wish to seek information or any suggestion may do it during the Parent-Teachers meeting. Those who wish to make any complaint about their children’s education or other matters should do so to the Principal, and not to the class teacher.</li>
                            <li>Parents/guardians shall not go to the class rooms during the school hours not call them out of the class. In the case of any necessity, they shall contact with the office.</li>
                            <li>When a student is likely to be absent owing to illness for a long period, the Principal must be informed through an application for leave supported by Medical Certificate within a week, failure to do so will result in the removal of child's name from the school roll.</li>
                            <li>The Principal or teacher may punish a student for habitual idleness, disobedience or misconduct, indiscipline, not preparing the lessons etc. by suspension or removal without assigning any reason for the action.</li>
                            <li>Criticism of a student’s teacher in his/her presence must be avoided. In case of a legitimate complaint, the Principal must be contacted.</li>
                            <li>Children must be trained in self-help by encouraging them to work and study. They should be taught to keep their rooms clean and tidy, make their own beds, polish their own shoes, carry their own bags, etc. The formation of such habits in early life will inculcate in them the dignity of work, a fundamental necessity for a successful career.</li>
                            <li>The school authorities are not responsible for the safety of a student after the school hours. Necessary arrangements must therefore be made to take the children home immediately after the class.</li>
                            <li>If a student regularly fails in wearing school uniform, not bringing proper books to class, not getting the signature of the parents on remarks written by teachers or on the absence record in the Hand book or not paying the fees in time, the student is obliged to return home during class hours.</li>
                            <li>No school business will be transacted during the holidays or outside the school hours.</li>
                            <li>The one who signs in the admission form of the student will be responsible for the student and is supposed to contact the school for the Parent-Teacher meeting or for any other purpose. If the student does not reside with the parents, the parents will have to introduce the guardian or the person incharge to the Principal. The school will not accept any other person to represent the guardian.</li>
                            <li>Parents are advised not to provide bike to the students for their conveyance.</li>
                            <li>Learner's Licence and Helmet is a must for the students coming to the school by two wheelers.</li>
                        </ul>
                    </div>
                    
                </div>
            </div>
        </div>
        <Footer />
        </>
    )
}
export default Recommendation