
import Header from '../Component/Header'
import Footer from '../Component/Footer'
import Breadcrumb from '../Component/Breadcrumb'
const About = () => {
    return(
        <>
        <Header />
        <Breadcrumb title="General Rules" pageName="General Rules" mid="Academics" />
        <div className="innerSection">
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12'>
                         <h3 className='inner_title'>General Rules</h3>
                        <ul>
                            <li>Continuance on the rolls of the school is subject to satisfactory progress in studies. Any student who fails for the second consecutive year in the same class will not be permitted to continue in the school.</li>
                            <li>Students must be regular and punctual in attendance. For leave of absence previous sanction is to be obtained from the Principal in writing.</li>
                            <li>Leave of absence during the term will not be granted except under very special circumstances.</li>
                            <li>Requests for leave on medical ground must be supported by certificate of a registered medical practitioner. In this case the application must be forwarded at least on the second day of the leave.</li>
                            <li>Fee must be paid regularly. A student’s name is liable to be struck off if the fees are in arrears at the end of the respective quarter.</li>
                            <li>All dues and arrears must be cleared before the Half Yearly and Annual Examination respectively.</li>
                            <li>Students may be required to attend extra classes on any working day after school hours, or on holidays, provided they have been informed on the previous day. Students themselves must make the alternative arrangement for their conveyance etc. if necessary.</li>
                            <li>Students will not be allowed to leave the school during the school hours. So parents are advised not to come with such requests</li>
                            <li>Students shall not be permitted to absent from classes for the sake of celebrations and festivals.</li>
                            <li>All students will have to take part in the extra-curricular activities, co-curricular activities, drill etc. No requests for exemption will be entertained.</li>
                            <li>No student is allowed to bring mobile phones, camera, radio or any other electronic items to the school without prior permission. If found in possession, they will be dismissed from the school without any prior notice.</li>
                            <li>Only those who attained permissible age and has a valid Driving License will be allowed to come to the school on two wheelers.</li>
                        </ul>
                    </div>
                    
                </div>
            </div>
        </div>
        <Footer />
        </>
    )
}
export default About