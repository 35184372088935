import Header from '../Component/Header'
import Footer from '../Component/Footer'
import Breadcrumb from '../Component/Breadcrumb'
import LazyLoad from 'react-lazyload'
const FeeStructure = () => {
    return (
        <>
            <Header />
            <Breadcrumb title="Co-Curricular Activities" pageName="Co-Curricular Activities" mid="Academics" />
            <div className="innerSection">
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <h3 className='inner_title'>Co-Curricular Activities</h3>
                        </div>
                        <div className='col-lg-12'>
                            <div class="panel-group" id="accordion">
                                <div class="panel panel-default">
                                    <div class="panel-heading">
                                        <h3 class="panel-title sub_title">Educational Tour</h3>
                                    </div>
                                    <div class="panel-body row">
                                        <p>Educational tours are arranged in every alternative year to various places of historical and cultural importance, such as Delhi, Agra, Jaipur, Bhuvaneshwar, Puri, Bombay, Goa, Chennai, Bangalore, Mysore, Ooty, Cochin, Darjeeling, Amritsar, Gangtok, Shimla, Chandigarh, Jalandar, Kanyakumari, Trivandrum, Munnar, Hyderabad etc.</p>
                                        <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <LazyLoad><img src="https://webapi.entab.info/api/image/LFSGV/public/Images/tour1.jpg" class="img-fluid incomimg border_img" alt="Little flower school, gita vatika" /></LazyLoad>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <LazyLoad><img src="https://webapi.entab.info/api/image/LFSGV/public/Images/tour2.jpg" class="img-fluid incomimg border_img" alt="Little flower school, gita vatika" /></LazyLoad>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <LazyLoad><img src="https://webapi.entab.info/api/image/LFSGV/public/Images/tour3.jpg" class="img-fluid incomimg border_img" alt="Little flower school, gita vatika" /></LazyLoad>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <LazyLoad><img src="https://webapi.entab.info/api/image/LFSGV/public/Images/tour4.jpg" class="img-fluid incomimg border_img" alt="Little flower school, gita vatika" /></LazyLoad>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <LazyLoad><img src="https://webapi.entab.info/api/image/LFSGV/public/Images/tour5.jpg" class="img-fluid incomimg border_img" alt="Little flower school, gita vatika" /></LazyLoad>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <LazyLoad><img src="https://webapi.entab.info/api/image/LFSGV/public/Images/tour6.jpg" class="img-fluid incomimg border_img" alt="Little flower school, gita vatika" /></LazyLoad>
                                        </div>
                                    </div>
                                </div>
                                <div class="panel panel-default">
                                    <div class="panel-heading">
                                        <h3 class="panel-title sub_title">Science &amp; Technology</h3>
                                    </div>
                                    <div class="panel-body row">
                                        <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <LazyLoad><img src="https://webapi.entab.info/api/image/LFSGV/public/Images/st1.jpg" class="img-fluid border_img incomimg" alt="Little flower school, gita vatika" /></LazyLoad>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <LazyLoad><img src="https://webapi.entab.info/api/image/LFSGV/public/Images/st2.jpg" class="img-fluid incomimg border_img" alt="Little flower school, gita vatika" /></LazyLoad>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <LazyLoad><img src="https://webapi.entab.info/api/image/LFSGV/public/Images/st3.jpg" class="img-fluid incomimg border_img" alt="Little flower school, gita vatika" /></LazyLoad>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <LazyLoad><img src="https://webapi.entab.info/api/image/LFSGV/public/Images/st4.jpg" class="img-fluid incomimg border_img" alt="Little flower school, gita vatika" /></LazyLoad>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <LazyLoad><img src="https://webapi.entab.info/api/image/LFSGV/public/Images/st5.jpg" class="img-fluid incomimg border_img" alt="Little flower school, gita vatika" /></LazyLoad>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <LazyLoad><img src="https://webapi.entab.info/api/image/LFSGV/public/Images/st6.jpg" class="img-fluid incomimg border_img" alt="Little flower school, gita vatika" /></LazyLoad>
                                        </div>
                                    </div>
                                </div>
                                <div class="panel panel-default">
                                    <div class="panel-heading">
                                        <h3 class="panel-title sub_title">Music &amp; Entertainment</h3>
                                    </div>
                                    <div class="panel-body row">
                                        <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <LazyLoad><img src="https://webapi.entab.info/api/image/LFSGV/public/Images/me1.jpg" class="img-fluid incomimg border_img" alt="Little flower school, gita vatika" /></LazyLoad>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <LazyLoad><img src="https://webapi.entab.info/api/image/LFSGV/public/Images/me2.jpg" class="img-fluid incomimg border_img" alt="Little flower school, gita vatika" /></LazyLoad>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <LazyLoad><img src="https://webapi.entab.info/api/image/LFSGV/public/Images/me3.jpg" class="img-fluid incomimg border_img" alt="Little flower school, gita vatika" /></LazyLoad>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <LazyLoad><img src="https://webapi.entab.info/api/image/LFSGV/public/Images/me4.jpg" class="img-fluid incomimg border_img" alt="Little flower school, gita vatika" /></LazyLoad>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <LazyLoad><img src="https://webapi.entab.info/api/image/LFSGV/public/Images/me5.jpg" class="img-fluid incomimg border_img" alt="Little flower school, gita vatika" /></LazyLoad>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <LazyLoad><img src="https://webapi.entab.info/api/image/LFSGV/public/Images/me6.jpg" class="img-fluid incomimg border_img" alt="Little flower school, gita vatika" /></LazyLoad>
                                        </div>
                                    </div>
                                </div>
                                <div class="panel panel-default">
                                    <div class="panel-heading">
                                        <h3 class="panel-title sub_title">Primary School Curriculum (Class L.K.G to V)</h3>

                                    </div>
                                    <div class="panel-body">
                                        <table class="table table-bordered">
                                            <tbody class="text-center">
                                                <tr>
                                                    <td>English (Language and Literature )</td>
                                                    <td>General Knowledge</td>
                                                </tr>
                                                <tr>
                                                    <td>Hindi ((Language and Literature )</td>
                                                    <td>Art</td>
                                                </tr>
                                                <tr>
                                                    <td>Mathematics</td>
                                                    <td>Music and Dance</td>
                                                </tr>
                                                <tr>
                                                    <td>General Science</td>
                                                    <td>Physical Education</td>
                                                </tr>
                                                <tr>
                                                    <td>Environmental Studies</td>
                                                    <td>Craft</td>
                                                </tr>
                                                <tr>
                                                    <td>Computer Studies</td>
                                                    <td>Drawing</td>
                                                </tr>
                                                <tr>
                                                    <td>Social Studies</td>
                                                    <td>Drill</td>
                                                </tr>
                                                <tr>
                                                    <td>Value Education</td>
                                                    <td>Painting</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="panel panel-default">
                                    <div class="panel-heading">
                                        <h3 class="panel-title sub_title">High School Section: (Class VI to X)</h3>
                                    </div>
                                    <div class="panel-body">
                                        <p>In the middle school and high school teachers provide students numerous opportunities to discover and nurture their talents, stimulate logical thinking and reasoning, develop self-confidence and leadership skills.</p>
                                        <div class="clr20"></div>
                                        <table class="table table-bordered">
                                            <tbody class="text-center">
                                                <tr>
                                                    <td>English (Language and Literature )</td>
                                                    <td>History and Civics</td>
                                                </tr>
                                                <tr>
                                                    <td>Hindi (Language and Literature )</td>
                                                    <td>Geography</td>
                                                </tr>
                                                <tr>
                                                    <td>Sanskrit</td>
                                                    <td>General Knowledge</td>
                                                </tr>
                                                <tr>
                                                    <td>Mathematics</td>
                                                    <td>SUPW</td>
                                                </tr>
                                                <tr>
                                                    <td>Physics</td>
                                                    <td>Physical Education</td>
                                                </tr>
                                                <tr>
                                                    <td>Chemistry</td>
                                                    <td>Moral Science</td>
                                                </tr>
                                                <tr>
                                                    <td>Biology</td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default FeeStructure