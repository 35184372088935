
import Header from '../Component/Header'
import Footer from '../Component/Footer'
import Breadcrumb from '../Component/Breadcrumb'
import LazyLoad from 'react-lazyload';
const About = () => {
    return(
        <>
        <Header />
        <Breadcrumb title="About Us" pageName="Introduction" mid="About" />
        <div className="innerSection">
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12'>
                         <h3 className='inner_title'>Introduction</h3>
                        <p>Little Flower School established and administered by the Catholic Diocese of Gorakhpur, is premier English Medium School of Eastern Uttar Pradesh. It was founded on <b>14th July 1984 </b>with <b>110 students</b> in Class VI as the fulfilment of the long cherished dream of the people of Gorakhpur and the response of the Catholic Mission to render quality education in the higher level to the children studying in primary schools. Being governed by the Catholic Church, a minority community the school has the primary objective of educating the children of the community. However, we shall not spare any effort in rendering our service to all people, irrespective of caste, sex, creed or religion, so as to conserve their religion, language and culture. The school aims at the integral development of the personality of every child, to make him committed to the Service of God and of the nation, to instil into him a spirit of compassion, unselfish service, dedication to duty, love for fellowmen and a thirst for excellence and wisdom. It will be our earnest effort to give equal importance to the moral development of the students.</p>
                        <p>Being governed by the Catholic Church, a minority community, the school has the primary objective of educating the children of the community. However, we shall not spare any effort in rendering our service to all people, irrespective of caste and creed, so as to conserve their religion, language and culture.</p>
                        <p>Students are encouraged to maintain high ideals and strive forexcellence in all fields as well as inculcating into them the values of freedom and its judicious use, and respect for law and order. They are taught to abide by moral principles, and unselfishness in the service of their country and fellowmen.</p>
                        <p>The school got No Objection Certificate (NOC) from Government of India in 1986 and affiliations to the Council for Indian School Certificate Examinations (CISCE), New Delhi in 1987. The primary section was opened in 1987 with one section in LKG; and the permission to open ISC Section (class XI) was given in 1992. The first batch of class X (ICSE) was presented for Board Examination in 1989 and Class XII (ISC) in 1994. Till now nineteen batches have given Class X-ICSE- examinations and fourteen batches class XII-ISC examination; all of them brought laurels to the institution. In the past years many have qualified meritoriously in the State and All India competitions. It is indeed rewarding to learn that many of our old students have got into covetable roles in life.</p>
                    </div>
                    <div className='col-lg-12'>
                        <LazyLoad><img src="https://webapi.entab.info/api/image/LFSGV/public/Images/about-img.jpg" className='img-fluid border_img' alt="Little Flower School, Gita Vatika" /></LazyLoad>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
        </>
    )
}
export default About